import React, { useEffect, useState } from "react";
import "./RideHistory.css";
import Sidebar from "./sidebar/Sidebar";
import Navbar from "./navbar/Navbar";
import axios from "axios";

import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Pagination,
  TextField,
  Button,
} from "@mui/material";

const RideHistory = () => {
  const [rideData, setRideData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [filteredRideData, setFilteredRideData] = useState([]);
  const [page, setPage] = useState(1);
  const rowsPerPage = 10;

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token");
      try {
        const response = await axios.get(
          "https://api.holocabs.in/driverConfirm/ride-history",
          {
            headers: {
              Authorization: token,
            },
          }
        );
        setRideData(response.data.reverse());
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  const handleSearch = () => {
    const filteredData = rideData.filter((ride) => {
      const details = ride.details;
      const last10DigitsOfId = ride.driverHistory._id.slice(-10);
      const searchTerms = [
        details.userName,
        last10DigitsOfId.toString(),
        details.driverName,
        details.vehicleType,
        details.driverMobile,
        details.driverId,
        details.getOriginalId,
      ];

      return searchTerms.some(
        (term) => term && term.toLowerCase().includes(searchText.toLowerCase())
      );
    });

    setFilteredRideData(filteredData);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const convertToIST = (timestamp) => {
    const date = new Date(parseInt(timestamp));

    // Check if the date is valid
    if (isNaN(date.getTime())) {
      return "Invalid Date";
    }

    return date.toLocaleString("en-IN", { timeZone: "Asia/Kolkata" });
  };

  const convertISOToIST = (isoDate) => {
    const date = new Date(isoDate);
    return date.toLocaleString("en-IN", { timeZone: "Asia/Kolkata" });
  };

  const indexOfLastRide = page * rowsPerPage;
  const indexOfFirstRide = indexOfLastRide - rowsPerPage;
  const currentRides = searchText === "" ? rideData : filteredRideData;
  const currentRidesOnPage = currentRides.slice(
    indexOfFirstRide,
    indexOfLastRide
  );

  const OpenWP = (type, number) => {
    if (!type || !number) return;

    // Define templates with proper structure and spacing
    const DriverTemplate = `
Namaskar Holo ତରଫ ରୁ 🙏🏻
Passenger ଅପେକ୍ଷା କରିଛନ୍ତି।
ଦୟା କରି pick-up ପାଇଁ ଯାଆନ୍ତୁ।
ଧନ୍ୟବାଦ୍🙏🏻😊
ଯଦି କିଛି issue ଆସେ ଆମକୁ ଏଠି ଜଣେଇବେ ଦୟା କରି cancel କରିବେ ନାହିଁ 🙏🏻
HOLO CABS RIDE DUE AMOUNT plus PICKUP charge ଆପଣଙ୍କ bank account ରେ ଜମା ହୋଇ ଯିବ। 🙏🏻😊
Extra pick-up distance ପାଇଁ extra charges ମିଳିବ company ତରଫ ରୁ 🙏🏻😊
`;

    const PassengerTemplate = `
Hello, from Holo Cabs.
Driver is reaching your pickup soon🙏🏻😊
You may call directly to the driver if you face IVR CONNECTIVITY ISSUES.
If you face any issues please reply here. Thank you, we sincerely request you not to cancel and give us a chance to serve you🙏🏻😊
`;

    // URL-encode the message to preserve structure and emojis
    const encodeMessage = (message) => encodeURIComponent(message);

    const template =
      type === "driver"
        ? encodeMessage(DriverTemplate)
        : encodeMessage(PassengerTemplate);

    // Create the WhatsApp link
    //const whatsappLink = `https://wa.me/${number}?text=${template}`;

    const whatsappLink = `https://api.whatsapp.com/send?phone=+91${number}&text=${template}`;

    // Open the link in a new tab
    window.open(whatsappLink, "_blank");
  };

  const verifyOTP = async (rideId) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.put(
        `https://api.holocabs.in/driver/drivers/${rideId}/destinationstart`,
        {},
        {
          headers: {
            Authorization: token,
          },
        }
      );
      if (response.status === 200) {
        alert("OTP Verified Successfully");
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className="list">
      <div className="sidebar">
        <Sidebar />
      </div>
      <div className="listContainer">
        <div className="navbar">
          <Navbar />
        </div>
        <h1 style={{ padding: 10 }}>Ride Activities</h1>
        <div
          className="search"
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <TextField
            label="Search"
            variant="outlined"
            style={{
              marginLeft: 20,
              marginTop: 20,
              height: "40px",
              borderRadius: 5,
              paddingLeft: 5,
              outline: "none",
              width: 300,
              paddingBottom: 50,
            }}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
          <Button
            variant="contained"
            color="primary"
            style={{ width: "150px", marginTop: 20, marginLeft: 20 }}
            onClick={handleSearch}
          >
            Search
          </Button>
        </div>
        <div className="ridehistorybox">
          <TableContainer component={Paper}>
            <Table>
              <TableHead style={{ backgroundColor: "orange" }}>
                <TableRow>
                  <TableCell>Ride ID</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Driver Accepted At</TableCell>
                  <TableCell>Ride Completed At</TableCell>
                  <TableCell>Customer Name</TableCell>
                  <TableCell>Customer ID</TableCell>
                  <TableCell>Driver Name</TableCell>
                  <TableCell>Vehicle Type</TableCell>
                  <TableCell>Driver ID</TableCell>
                  <TableCell>Driver Mobile</TableCell>
                  <TableCell>Fare Charge</TableCell>
                  <TableCell>Live Location</TableCell>
                  <TableCell>INFORM DRIVER WP</TableCell>
                  <TableCell>INFORM PASSENGER WP</TableCell>
                  <TableCell>Verify OTP by Admin</TableCell>
                  <TableCell>Pickup Location</TableCell>
                  <TableCell>Destination Location</TableCell>
                  <TableCell>Distance</TableCell>
                  <TableCell>Ride Status</TableCell>
                  <TableCell>Payment Status</TableCell>
                  <TableCell>OTP Status</TableCell>
                  <TableCell>RIDER OTP</TableCell>
                  <TableCell>Pickup Distance</TableCell>
                  <TableCell>Extra Distance</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {currentRidesOnPage.map((ride, index) => (
                  <TableRow key={index}>
                    <TableCell>{ride.driverHistory._id.slice(-10)}</TableCell>
                    <TableCell>
                      {convertISOToIST(ride.driverHistory.date)}
                    </TableCell>
                    <TableCell>
                      {convertToIST(ride.driverHistory.driveracceptedat)}
                    </TableCell>
                    <TableCell>
                      {convertToIST(ride.driverHistory.ridecompletedat)}
                    </TableCell>
                    <TableCell>{ride.details.userName}</TableCell>
                    <TableCell>
                      <a href={`/users/${ride.details.getOriginalId}`}>
                        {ride.details.getOriginalId}
                      </a>
                    </TableCell>
                    <TableCell>{ride.details.driverName}</TableCell>
                    <TableCell>{ride.details.vehicleType}</TableCell>
                    <TableCell>
                      <a href={`/drivers/${ride.driverHistory.driverId}`}>
                        {ride.driverHistory.driverId}
                      </a>
                    </TableCell>
                    <TableCell>{`${ride.details.driverMobile}`}</TableCell>
                    <TableCell>{`₹${ride.details.price}`}</TableCell>
                    <TableCell>
                      {ride.driverHistory.rideCompleted ? (
                        "NOT AVAILABLE"
                      ) : (
                        <a
                          href={`https://main.holocabs.com/Singlemaps/${ride.details.liveLocationKey}`}
                        >
                          LINK
                        </a>
                      )}
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() =>
                          OpenWP("driver", ride.details.driverMobile)
                        }
                      >
                        INFORM DRIVER WP
                      </Button>
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() =>
                          OpenWP("passenger", ride.details.userMobile)
                        }
                      >
                        INFORM PASSENGER WP
                      </Button>
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="outlined"
                        color="success"
                        onClick={() => verifyOTP(ride.driverHistory.driverId)}
                      >
                        Verify
                      </Button>
                    </TableCell>
                    <TableCell
                      style={{
                        padding: "5px",
                        whiteSpace: "normal",
                        wordBreak: "break-word",
                      }}
                    >
                      {ride.details.plocation}
                    </TableCell>
                    <TableCell
                      style={{
                        padding: "5px",
                        whiteSpace: "normal",
                        wordBreak: "break-word",
                      }}
                    >
                      {ride.details.dlocation}
                    </TableCell>
                    <TableCell>{`${ride.details.distance} KM`}</TableCell>
                    <TableCell>
                      {ride.driverHistory.rideCompleted
                        ? "Completed"
                        : "Not Completed"}
                    </TableCell>
                    <TableCell>
                      {ride.driverHistory.paymentCompleted
                        ? "Completed"
                        : "Not Completed"}
                    </TableCell>
                    <TableCell>
                      {ride.details.destinationStart ||
                      ride.driverHistory.paymentCompleted
                        ? "VERIFIED"
                        : "NOT VERIFIED"}
                    </TableCell>
                    <TableCell>{ride.details.otpforValid}</TableCell>
                    <TableCell>{ride.details.pickupDistance}</TableCell>
                    <TableCell>{ride.details.extraDistance}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            count={Math.ceil(currentRides.length / rowsPerPage)}
            page={page}
            onChange={handlePageChange}
          />
        </div>
      </div>
    </div>
  );
};

export default RideHistory;
