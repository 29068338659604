import React, { useEffect, useState, memo } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import Widget from "../../components/Widget";
import "./home.scss";
import axios from "axios";
import { CircularProgress } from "@mui/material";

const Home = () => {
  const [stats, setStats] = useState({
    numberUser: 0,
    numberDriver: 0,
    onlineDrivers: 0,
    onlineAutoDrivers: 0,
    onlineCabDrivers: 0,
    offlineDrivers: 0,
    ridingDrivers: 0,
    regularVehicles: 0,
    primeSedanVehicles: 0,
    suvPremiumVehicles: 0,
    holoAutoVehicles: 0,
    driverData: [],
  });

  const [rideData, setRideData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          throw new Error("No token found in localStorage");
        }

        // Fetch all users
        const userResponse = await axios.get(
          "https://api.holocabs.in/passenger/getalluser",
          { headers: { Authorization: token } }
        );

        // Fetch all drivers
        const driverResponse = await axios.get(
          "https://api.holocabs.in/driver/getalldriver",
          { headers: { Authorization: token } }
        );

        const drivers = driverResponse.data;

        const derivedStats = {
          numberUser: userResponse.data.length,
          numberDriver: drivers.length,
          onlineDrivers: drivers.filter((d) => d.isAvailable).length,
          onlineAutoDrivers: drivers.filter(
            (d) => d.isAvailable && d.vehicleType === "Auto rickshaw"
          ).length,
          onlineCabDrivers: drivers.filter(
            (d) => d.isAvailable && d.vehicleType !== "Auto rickshaw"
          ).length,
          offlineDrivers: drivers.filter((d) => !d.isAvailable).length,
          ridingDrivers: drivers.filter((d) => d.currentDriving).length,
          regularVehicles: drivers.filter((d) => d.vehicleType === "Regular")
            .length,
          primeSedanVehicles: drivers.filter(
            (d) => d.vehicleType === "Prime/Sedan"
          ).length,
          suvPremiumVehicles: drivers.filter(
            (d) => d.vehicleType === "SUV/Premium"
          ).length,
          holoAutoVehicles: drivers.filter(
            (d) => d.vehicleType === "Auto rickshaw"
          ).length,
          driverData: drivers,
        };

        setStats(derivedStats);

        // Fetch ride history
        const rideHistoryResponse = await axios.get(
          "https://api.holocabs.in/driverConfirm/ride-history",
          { headers: { Authorization: token } }
        );
        setRideData(rideHistoryResponse.data);
      } catch (err) {
        console.error("Error fetching data:", err);
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress color="success" />
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const widgetTypes = [
    "user",
    "order",
    "earning",
    "balance",
    "onlineDrivers",
    "onlineAutoDrivers",
    "onlineCabDrivers",
    "offlineDrivers",
    "ridingDrivers",
    "regularVehicles",
    "primeSedanVehicles",
    "suvPremiumVehicles",
    "HoloAutoVehicles",
    "DriversDownload",
    "ActiveDriversDownload",
    "CabsDriversDownload",
  ];

  return (
    <div className="home">
      <Sidebar />
      <div className="homeContainer">
        <Navbar />
        <div className="widgets">
          {widgetTypes.map((type) => (
            <Widget key={type} type={type} stats={stats} rideData={rideData} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default memo(Home);
