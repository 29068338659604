import "./widget.scss";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import CarIcon from "@mui/icons-material/DirectionsCar";
import ToggleOnIcon from "@mui/icons-material/ToggleOn"; // Online Drivers
import ToggleOffIcon from "@mui/icons-material/ToggleOff"; // Offline Drivers
import LocalTaxiIcon from "@mui/icons-material/LocalTaxi"; // Drivers in Ride
import DirectionsCarFilledOutlinedIcon from "@mui/icons-material/DirectionsCarFilledOutlined"; // Vehicle Icons
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { handleAllDownloadInfo } from "../datatablesource";

const Widget = ({ type, stats, rideData }) => {
  const ridenumber = rideData.length;

  let data;
  switch (type) {
    case "user":
      data = {
        title: "Users",
        isMoney: false,
        link: "see all users",
        icon: (
          <PersonOutlineOutlinedIcon
            className="icon"
            style={{ color: "crimson", backgroundColor: "rgba(248,0,0,0.2)" }}
          />
        ),
      };
      break;

    case "order":
      data = {
        title: "Drivers",
        isMoney: false,
        link: "View all Drivers",
        icon: (
          <PersonOutlineOutlinedIcon
            className="icon"
            style={{ color: "black", backgroundColor: "#edca11" }}
          />
        ),
      };
      break;

    case "earning":
      data = {
        title: "Rides",
        isMoney: false,
        link: "View History",
        icon: (
          <CarIcon
            className="icon"
            style={{ backgroundColor: "rgba(0,128,0,0.2)", color: "green" }}
          />
        ),
      };
      break;

    case "balance":
      data = {
        title: "BALANCE",
        isMoney: true,
        link: "See details",
        icon: (
          <AccountBalanceOutlinedIcon
            className="icon"
            style={{ backgroundColor: "rgba(128,0,128,0.2)", color: "purple" }}
          />
        ),
      };
      break;

    case "onlineDrivers":
      data = {
        title: "Online Drivers",
        isMoney: false,
        link: "View all Online Drivers",
        icon: (
          <ToggleOnIcon
            className="icon"
            style={{ backgroundColor: "rgba(0,128,0,0.2)", color: "green" }}
          />
        ),
      };
      break;

    case "onlineAutoDrivers":
      data = {
        title: "Online Auto Drivers",
        isMoney: false,
        link: "View all Online Auto Drivers",
        icon: (
          <ToggleOnIcon
            className="icon"
            style={{ backgroundColor: "rgba(0,128,0,0.2)", color: "green" }}
          />
        ),
      };
      break;

    case "onlineCabDrivers":
      data = {
        title: "Online Cab Drivers",
        isMoney: false,
        link: "View all Online Cab Drivers",
        icon: (
          <ToggleOnIcon
            className="icon"
            style={{ backgroundColor: "rgba(0,128,0,0.2)", color: "green" }}
          />
        ),
      };
      break;

    case "offlineDrivers":
      data = {
        title: "Offline Drivers",
        isMoney: false,
        link: "View all Offline Drivers",
        icon: (
          <ToggleOffIcon
            className="icon"
            style={{ backgroundColor: "rgba(255,0,0,0.2)", color: "red" }}
          />
        ),
      };
      break;

    case "ridingDrivers":
      data = {
        title: "Drivers in Ride",
        isMoney: false,
        link: "View all Drivers in Ride",
        icon: (
          <LocalTaxiIcon
            className="icon"
            style={{ backgroundColor: "black", color: "yellow" }}
          />
        ),
      };
      break;

    case "regularVehicles":
      data = {
        title: "Regular Vehicles",
        isMoney: false,
        link: "View all Regular Vehicles",
        icon: (
          <DirectionsCarFilledOutlinedIcon
            className="icon"
            style={{ backgroundColor: "rgba(128,128,128,0.2)", color: "gray" }}
          />
        ),
      };
      break;

    case "primeSedanVehicles":
      data = {
        title: "Prime/Sedan Vehicles",
        isMoney: false,
        link: "View all Prime/Sedan Vehicles",
        icon: (
          <DirectionsCarFilledOutlinedIcon
            className="icon"
            style={{ backgroundColor: "rgba(0,128,0,0.2)", color: "green" }}
          />
        ),
      };
      break;

    case "suvPremiumVehicles":
      data = {
        title: "SUV/Premium Vehicles",
        isMoney: false,
        link: "View all SUV/Premium Vehicles",
        icon: (
          <DirectionsCarFilledOutlinedIcon
            className="icon"
            style={{ backgroundColor: "rgba(128,0,0,0.2)", color: "red" }}
          />
        ),
      };
      break;

    case "HoloAutoVehicles":
      data = {
        title: "Holo Auto",
        isMoney: false,
        link: "View all Holo Auto's",
        icon: (
          <DirectionsCarFilledOutlinedIcon
            className="icon"
            style={{ color: "black", backgroundColor: "#edca11" }}
          />
        ),
      };
      break;

    case "DriversDownload":
      data = {
        title: "Online Drivers Data",
        isMoney: false,
        link: "View all online drivers",
        icon: (
          <CloudDownloadIcon
            className="icon"
            style={{ color: "#ffffff", backgroundColor: "#000" }}
          />
        ),
      };
      break;

    case "ActiveDriversDownload":
      data = {
        title: "Most Active Drivers Data",
        isMoney: false,
        link: "View all",
        icon: (
          <CloudDownloadIcon
            className="icon"
            style={{ color: "green", backgroundColor: "#000" }}
          />
        ),
      };
      break;

    case "CabsDriversDownload":
      data = {
        title: "Cab Online Drivers",
        isMoney: false,
        link: "View all",
        icon: (
          <CarIcon
            className="icon"
            style={{ backgroundColor: "rgba(0,128,0,0.2)", color: "green" }}
          />
        ),
      };
      break;

    default:
      break;
  }

  return (
    <div className="widget">
      <div className="left">
        <span className="title">{data.title}</span>
        <span className="counter">
          {data.title === "Users" && stats.numberUser}
          {data.title === "Drivers" && stats.numberDriver}
          {data.title === "Rides" && ridenumber}
          {data.title === "BALANCE" && "XXXX"}
          {data.title === "Online Drivers" && stats.onlineDrivers}
          {data.title === "Online Auto Drivers" && stats.onlineAutoDrivers}
          {data.title === "Online Cab Drivers" && stats.onlineCabDrivers}
          {data.title === "Offline Drivers" && stats.offlineDrivers}
          {data.title === "Drivers in Ride" && stats.ridingDrivers}
          {data.title === "Regular Vehicles" && stats.regularVehicles}
          {data.title === "Prime/Sedan Vehicles" && stats.primeSedanVehicles}
          {data.title === "SUV/Premium Vehicles" && stats.suvPremiumVehicles}
          {data.title === "Holo Auto" && stats.holoAutoVehicles}
        </span>

        {data.title === "Online Drivers Data" && (
          <>
            <button
              onClick={() => handleAllDownloadInfo(stats.driverData)}
              style={{
                backgroundColor: "#000",
                borderRadius: "4px",
                justifyContent: "center",
              }}
            >
              <span style={{ color: "#ffffff" }}>Download</span>
            </button>
          </>
        )}

        {data.title === "Most Active Drivers Data" && (
          <>
            <button
              onClick={() => handleAllDownloadInfo(stats.driverData, true)}
              style={{
                backgroundColor: "#000",
                borderRadius: "4px",
                justifyContent: "center",
              }}
            >
              <span style={{ color: "green" }}>Download</span>
            </button>
          </>
        )}

        {data.title === "Cab Online Drivers" && (
          <>
            <button
              onClick={() => handleAllDownloadInfo(stats.driverData, true, true)}
              style={{
                backgroundColor: "#000",
                borderRadius: "4px",
                justifyContent: "center",
              }}
            >
              <span style={{ color: "yellow" }}>Download</span>
            </button>
          </>
        )}

        <span className="link">{data.link}</span>
      </div>
      <div className="right">
        <div className="percentage positive">
          <KeyboardArrowUpOutlinedIcon />
          {data.title === "Users" && "25%"}
          {data.title === "Drivers" && "22%"}
          {data.title === "Rides" && "5%"}
          {data.title === "BALANCE" && "X%"}
          {data.title === "Online Drivers" && "15%"}
          {data.title === "Online Auto Drivers" && "15%"}
          {data.title === "Online Cab Drivers" && "15%"}
          {data.title === "Offline Drivers" && "10%"}
          {data.title === "Drivers in Ride" && "8%"}
          {data.title === "Regular Vehicles" && "12%"}
          {data.title === "Prime/Sedan Vehicles" && "18%"}
          {data.title === "SUV/Premium Vehicles" && "20%"}
          {data.title === "Holo Auto" && "20%"}
        </div>
        {data.icon}
      </div>
    </div>
  );
};

export default Widget;
