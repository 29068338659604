import React, { useEffect, useState } from "react";
import axios from "axios";
import "./single.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import Chart from "../../components/chart/Chart";
import List from "../../components/table/Table";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button } from "@mui/material";
import Modal from "react-modal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { set } from "date-fns";
const Single = () => {
  const navigation = useNavigate();

  const [user, setUser] = useState(null);
  const userId = useParams().userId;

  const url = window.location.href;
  const parts = url.split("/");
  const desiredValue = parts[parts.length - 2];
  const driverId = parts[parts.length - 1];
  const [data, setData] = useState([]);
  const [showActivateModal, setShowActivateModal] = useState(false);
  const [showDeactivateModal, setShowDeactivateModal] = useState(false);

  //  console.log(desiredValue);
  //setShowActivateModal set to true
  // setShowDeactivateModal set to true

  const confirmActivate = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.patch(
        `https://api.holocabs.in/driver/drivers/do-activation/${driverId}`,
        {},
        {
          headers: {
            Authorization: token,
          },
        }
      );

      if (response.data.success) {
        // Handle success, maybe update the UI or show a success message
        toast.success("Driver activated successfully");
        setShowActivateModal(false);
      } else {
        // Handle failure, show an error message or take appropriate action

        toast.error("Failed to activate driver");
      }
    } catch (error) {
      console.error("Error activating driver:", error);
    }
  };

  const confirmDeactivate = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.patch(
        `https://api.holocabs.in/driver/drivers/do-deactivation/${driverId}`,
        {},
        {
          headers: {
            Authorization: token,
          },
        }
      );

      if (response.data.success) {
        // Handle success, maybe update the UI or show a success message

        toast.success("Driver deactivated successfully");
        setShowDeactivateModal(false);
      } else {
        // Handle failure, show an error message or take appropriate action

        toast.error("Failed to deactivate driver");
      }
    } catch (error) {
      console.error("Error deactivating driver:", error);
    } finally {
      setShowDeactivateModal(false);
    }
  };

  const cancelActivate = () => {
    setShowActivateModal(false);
  };

  const cancelDeactivate = () => {
    setShowDeactivateModal(false);
  };

  const openWP = (number) => {
    const template = `
  🙏 ସ୍ବାଗତମ୍ !

HOLO CABS / AUTO ପରିବାରରେ ଆପଣଙ୍କୁ ସ୍ବାଗତ। ✨ ଆପଣଙ୍କ ଆକାଉଣ୍ଟ ସଫଳତାର ସହିତ ସକ୍ରିୟ ହୋଇଛି ✅ I ଆପଣଙ୍କର ଯାତ୍ରା ଶୁଭାରମ୍ଭ ହେଉ! 🛺

*ଆପଣଙ୍କ ପାଇଁ ଦିଆ ଯାଉଥିବା ନିର୍ଦ୍ଦେଶାବଳୀ*
1️⃣ ଯାତ୍ରୀଙ୍କ ସହ ସମ୍ମାନ ଓ ସାର୍ଥକ ସଂବାଦ ରଖନ୍ତୁ। 😊
2️⃣ ଆପଣଙ୍କ ଗାଡ଼ି ସଫା ଓ ସୁରକ୍ଷିତ ଅବସ୍ଥାରେ ରଖନ୍ତୁ। 🛺
3️⃣ଯାତ୍ରୀଙ୍କ ସାମାନ୍ୟ ଅସୁବିଧା କିମ୍ବା ପ୍ରଶ୍ନ ହେଲେ ସହଯୋଗ କରନ୍ତୁ।

*Holo Cabs ଏକ ନୂତନ ଅଭିଯାନ, ଆମ ସହିତ ଜଡିତ ହୋଇଥିବାରୁ ଧନ୍ୟବାଦ। ଭଲ ଯାତ୍ରା କରନ୍ତୁ! 🙏✨*

100% ଓଡ଼ିଶାରେ ନିର୍ମିତ !
ଓଡ଼ିଶାର ପ୍ରଥମ କ୍ୟାବ୍/ଅଟୋ ବୁକିଂ ଆପ୍ !

ଯଦି କିଛି ସମସ୍ୟା ❌ ବା ପ୍ରଶ୍ନ ❓ ହୋଇଥାଏ, ଦୟାକରି ଆମର ସହାୟତା କେନ୍ଦ୍ରକୁ ସମ୍ପର୍କ କରନ୍ତୁ। 📞

*📞 ଫୋନ୍ ସହାୟତା ନମ୍ବର: 9114795982,8480370377*
📧 ଇମେଲ୍: support@holocabs.com
✆Whatsapp✆- 9114795982
 

Apananka account *active kara heijaichi*, ebe apana app close Kariki aau thare kholantu ebang apana anubhav kariparibe apananka account active kara hoi sarichi.
Dhanyawad 🙏🏻😊
Team Holo Cabs
  `;

    const encodeMessage = (message) => encodeURIComponent(message);
    const finaltemplate = encodeMessage(template);
    const whatsappLink = `https://api.whatsapp.com/send?phone=+91${number}&text=${finaltemplate}`;

    window.open(whatsappLink, "_blank");
  };
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `https://api.holocabs.in/passenger/getuser/${userId}`,
          {
            headers: {
              Authorization: token,
            },
          }
        );
        setUser(response.data);
      } catch (error) {
        console.error("Error fetching user:", error);
      }
    };
    const fetchDriver = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `https://api.holocabs.in/driver/getdriverdetails/${driverId}`,
          {
            headers: {
              Authorization: token,
            },
          }
        );
        setUser(response.data);
      } catch (error) {
        console.error("Error fetching user:", error);
      }
    };

    if (desiredValue == "drivers") {
      fetchDriver();
    } else {
      fetchUser();
    }
  }, [showActivateModal, showDeactivateModal]);

  const clearFromRide = async (driverId) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.put(
        `https://api.holocabs.in/driver/drivers/${driverId}/falseallstatus`,
        {},
        {
          headers: {
            Authorization: token,
          },
        }
      );
      if (response.data.success) {
        toast.success("Ride cleared successfully");
      }
    } catch (error) {
      console.error("Error clearing ride:", error);
      toast.error("Something went wrong", error);
    }
  };
  return (
    <div className="single">
      <Sidebar />
      <ToastContainer />
      <div className="singleContainer">
        <Navbar />
        {/* Activation Confirmation Modal */}
        <Modal
          isOpen={showActivateModal}
          onRequestClose={cancelActivate}
          style={{
            content: {
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              width: "300px",
              height: "150px",
              margin: "auto",
            },
          }}
        >
          <h3>Confirm Activation</h3>
          <p>Are you sure you want to activate this driver?</p>
          <div>
            <button
              onClick={confirmActivate}
              style={{
                backgroundColor: "#4CAF50",
                border: "none",
                color: "white",
                padding: "10px 20px",
                textAlign: "center",
                textDecoration: "none",
                display: "inline-block",
                fontSize: "16px",
                margin: "4px 2px",
                cursor: "pointer",
                borderRadius: "4px",
              }}
            >
              Activate
            </button>
            <button
              onClick={cancelActivate}
              style={{
                backgroundColor: "#2196F3",
                border: "none",
                color: "white",
                padding: "10px 20px",
                textAlign: "center",
                textDecoration: "none",
                display: "inline-block",
                fontSize: "16px",
                margin: "4px 2px",
                cursor: "pointer",
                borderRadius: "4px",
              }}
            >
              Cancel
            </button>
          </div>
        </Modal>

        {/* Deactivation Confirmation Modal */}
        <Modal
          isOpen={showDeactivateModal}
          onRequestClose={cancelDeactivate}
          style={{
            content: {
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              width: "300px",
              height: "150px",
              margin: "auto",
            },
          }}
        >
          <h3>Confirm Deactivation</h3>
          <p>Are you sure you want to deactivate this driver?</p>
          <div>
            <button
              onClick={confirmDeactivate}
              style={{
                backgroundColor: "#F44336",
                border: "none",
                color: "white",
                padding: "10px 20px",
                textAlign: "center",
                textDecoration: "none",
                display: "inline-block",
                fontSize: "16px",
                margin: "4px 2px",
                cursor: "pointer",
                borderRadius: "4px",
              }}
            >
              Deactivate
            </button>
            <button
              onClick={cancelDeactivate}
              style={{
                backgroundColor: "#2196F3",
                border: "none",
                color: "white",
                padding: "10px 20px",
                textAlign: "center",
                textDecoration: "none",
                display: "inline-block",
                fontSize: "16px",
                margin: "4px 2px",
                cursor: "pointer",
                borderRadius: "4px",
              }}
            >
              Cancel
            </button>
          </div>
        </Modal>
        <div className="top">
          <div className="left">
            {desiredValue == "drivers" ? (
              <div className="editButton">
                <Link to={`/editdrivers/${driverId}`}>Edit</Link>
              </div>
            ) : (
              ""
            )}

            <h1 className="title">Information</h1>
            {user && (
              <div className="item">
                <img
                  src={user.profile_pic}
                  alt=""
                  className="itemImg"
                  onError={(e) => {
                    console.error(e);
                  }}
                />
                <div className="details">
                  <h1 className="itemTitle">
                    {desiredValue == "drivers" ? user.driverName : user.name}
                  </h1>

                  <div className="detailItem">
                    <span className="itemKey">Phone:</span>
                    <span className="itemValue">
                      {desiredValue == "drivers"
                        ? user.driverMobile
                        : user.mobile}
                    </span>
                  </div>

                  {desiredValue == "drivers" ? (
                    <div className="detailItem">
                      <span className="itemKey">Adhar Number:</span>
                      <span className="itemValue">{user.adharNo}</span>
                    </div>
                  ) : (
                    ""
                  )}

                  {desiredValue == "drivers" ? (
                    <div className="detailItem">
                      <span className="itemKey">Gender:</span>
                      <span className="itemValue">{user.driverGender}</span>
                    </div>
                  ) : (
                    ""
                  )}

                  {desiredValue == "drivers" ? (
                    <div className="detailItem">
                      <span className="itemKey">Vehicle Number:</span>
                      <span className="itemValue">{user.RCNumber}</span>
                    </div>
                  ) : (
                    ""
                  )}
                  {desiredValue == "drivers" ? (
                    <div className="detailItem">
                      <span className="itemKey">DL Number:</span>
                      <span className="itemValue">{user.dlNumber}</span>
                    </div>
                  ) : (
                    ""
                  )}
                  {desiredValue == "drivers" ? (
                    <div className="detailItem">
                      <span className="itemKey">Vehicle Type:</span>
                      <span className="itemValue">{user.vehicleType}</span>
                    </div>
                  ) : (
                    ""
                  )}
                  {desiredValue == "drivers" ? (
                    <div className="detailItem">
                      <span className="itemKey">Total Income:</span>
                      <span className="itemValue">{user.totalIncome}</span>
                    </div>
                  ) : (
                    ""
                  )}
                  {desiredValue == "drivers" ? (
                    <div className="detailItem">
                      <span className="itemKey">UPI ID:</span>
                      <span className="itemValue">{user.upiId}</span>
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="detailItem">
                    <span className="itemKey">Country:</span>
                    <span className="itemValue">{"INDIA"}</span>
                  </div>
                  {desiredValue == "drivers" ? (
                    <div className="allbuttons">
                      <Button
                        variant="contained"
                        onClick={() =>
                          navigation(
                            `/map/${user.location.coordinates[0]}/${user.location.coordinates[1]}`
                          )
                        }
                      >
                        See Location
                      </Button>
                      <Button
                        variant={user.isActivated ? "contained" : "outlined"}
                        onClick={() => {
                          if (user.isActivated) {
                            setShowDeactivateModal(true);
                          } else {
                            setShowActivateModal(true);
                          }
                        }}
                      >
                        {user.isActivated ? "Deactivate" : "Activate"}
                      </Button>
                      <Button
                        variant="outlined"
                        onClick={() => openWP(user.driverMobile)}
                      >
                        GREET
                      </Button>
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={() => clearFromRide(user._id)}
                      >
                        CLEAR INRIDE
                      </Button>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            )}
          </div>
          <div className="right">
            <Chart aspect={3 / 1} title="User Spending (Last 6 Months)" />
          </div>
        </div>
        <div className="bottom">
          <h1 className="title">Last Transactions</h1>
          <List />
        </div>
      </div>
    </div>
  );
};

export default Single;
