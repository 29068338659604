import React, { useState, useRef } from "react";
import {
  Modal,
  Button,
  Box,
  Typography,
  Switch,
  FormControlLabel,
  CircularProgress,
} from "@mui/material";
import ReactCrop, {
  centerCrop,
  convertToPixelCrop,
  makeAspectCrop,
} from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import setCanvasPreview from "./setCanvasPreview";
import "react-toastify/dist/ReactToastify.css";

import axios from "axios";
import { useNavigate } from "react-router-dom";
const ASPECT_RATIO = 1;
const MIN_DIMENSION = 150;

const ImageEditor = ({
  open,
  onClose,
  onSave,
  phonenumner,
  driverId,
  imageType,
  currentImage,
}) => {
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const fileInputRef = useRef(null);
  const [imgSrc, setImgSrc] = useState("");
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const [error, setError] = useState("");
  const [enableCropping, setEnableCropping] = useState(true);

  const [loading, setLoading] = useState(false);

  const navig = useNavigate();

  const onSelectFile = (e) => {
    const file = e.target.files?.[0];
    if (!file) return;

    const reader = new FileReader();
    reader.addEventListener("load", () => {
      const imageElement = new Image();
      const imageUrl = reader.result?.toString() || "";
      imageElement.src = imageUrl;

      imageElement.addEventListener("load", (e) => {
        if (error) setError("");
        const { naturalWidth, naturalHeight } = e.currentTarget;
        if (naturalWidth < MIN_DIMENSION || naturalHeight < MIN_DIMENSION) {
          setError("Image must be at least 150 x 150 pixels.");
          return setImgSrc("");
        }
        setImgSrc(imageUrl);
        onSave(imageUrl);
      });
    });
    reader.readAsDataURL(file);
  };

  const onImageLoad = (e) => {
    const { width, height } = e.currentTarget;
    const cropWidthInPercent = (MIN_DIMENSION / width) * 100;

    const crop = makeAspectCrop(
      {
        unit: "%",
        width: cropWidthInPercent,
      },
      ASPECT_RATIO,
      width,
      height
    );
    const centeredCrop = centerCrop(crop, width, height);
    setCrop(centeredCrop);
  };

  const onImageError = () => {
    setError("Failed to load image.");
    setImgSrc("");
  };

  const handleSave = async () => {
    setLoading(true);
    const isToken = localStorage.getItem("token");
    if (enableCropping) {
      // Handle cropping
      if (!imgRef.current || !previewCanvasRef.current || !completedCrop) {
        return;
      }
      setCanvasPreview(
        imgRef.current,
        previewCanvasRef.current,
        convertToPixelCrop(crop, imgRef.current.width, imgRef.current.height)
      );
      const dataUrl = previewCanvasRef.current.toDataURL();
      onSave(dataUrl);

      try {
        const blob = await fetch(dataUrl).then((res) => res.blob());
        const formData = new FormData();

        formData.append("image", blob, "cropped-image.png");
        formData.append("number", phonenumner);
        formData.append("docType", imageType);

        const response = await axios.post(
          "https://api.holocabs.in/driver/images/update",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: isToken,
            },
          }
        );
        if (response) {
          alert("Image updated successfully");
          navig(`/drivers/${driverId}`);
          // toast.success("Image updated successfully");
        } else {
          alert("Something unexpected");
          // toast.error("Something unexpected");
        }
      } catch (error) {
        console.error(error);
        alert(
          error.response?.data?.message ||
            "Failed to update the image. Try again."
        );
      } finally {
        setLoading(false);
      }
    } else {
      // If cropping is disabled, send the original file
      if (!fileInputRef.current?.files?.[0]) {
        alert("No file selected.");
        return;
      }

      const file = fileInputRef.current.files[0];
      //   onSave(file);

      try {
        const formData = new FormData();

        formData.append("image", file); // Append the raw file
        formData.append("number", phonenumner);
        formData.append("docType", imageType);

        const response = await axios.post(
          "https://api.holocabs.in/driver/images/update",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: isToken,
            },
          }
        );
        if (response) {
          alert("Image updated successfully");
          navig(`/drivers/${driverId}`);
        } else {
          alert("Something unexpected");
        }
      } catch (error) {
        console.error(error);
        alert(
          error.response?.data?.message ||
            "Failed to update the image. Try again."
        );
      } finally {
        setLoading(false);
      }
    }

    handleClose();
  };

  const handleClose = () => {
    onClose();
    setImgSrc("");
    setCrop(undefined);
    setCompletedCrop(undefined);
    setEnableCropping(true);

    setLoading(false);
    if (fileInputRef.current) fileInputRef.current.value = "";
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="image-editor-modal"
      >
        <Box sx={styles.modalBox}>
          <Typography variant="h6" component="h2" sx={styles.modalTitle}>
            Edit{" "}
            {imageType === "profile" ? "Profile Picture" : "Document Image"}
          </Typography>

          {error && (
            <Typography color="error" sx={styles.errorText}>
              {error}
            </Typography>
          )}

          <Typography variant="body2" sx={styles.aspectInfo}>
            {imageType === "profile"
              ? "Square aspect ratio (1:1) will be maintained"
              : "Document aspect ratio (4:3) will be maintained"}
          </Typography>

          <Box sx={styles.uploadSection}>
            <input
              accept="image/*"
              style={{ display: "none" }}
              id="raised-button-file"
              type="file"
              onChange={onSelectFile}
              ref={fileInputRef}
            />
            <label htmlFor="raised-button-file">
              <Button
                variant="contained"
                component="span"
                sx={styles.uploadButton}
              >
                Choose Image
              </Button>
            </label>
          </Box>

          {imgSrc && (
            <>
              <FormControlLabel
                control={
                  <Switch
                    checked={enableCropping}
                    onChange={(e) => setEnableCropping(e.target.checked)}
                    color="primary"
                  />
                }
                label="Enable Cropping"
                sx={styles.croppingToggle}
              />

              {enableCropping ? (
                <Box sx={styles.cropContainer}>
                  <ReactCrop
                    crop={crop}
                    onChange={(_, percentCrop) => setCrop(percentCrop)}
                    onComplete={(c) => setCompletedCrop(c)}
                    aspect={ASPECT_RATIO}
                    circularCrop={imageType === "profile"}
                    minWidth={MIN_DIMENSION}
                  >
                    <img
                      ref={imgRef}
                      alt="Crop me"
                      src={imgSrc}
                      onLoad={onImageLoad}
                      onError={onImageError}
                      style={styles.cropImage}
                    />
                  </ReactCrop>
                </Box>
              ) : (
                <Box sx={styles.imagePreview}>
                  <img src={imgSrc} alt="Preview" style={styles.previewImage} />
                </Box>
              )}
            </>
          )}

          {/* Hidden canvas for cropping */}
          <canvas ref={previewCanvasRef} style={{ display: "none" }} />

          <Box sx={styles.actionButtons}>
            <Button
              variant="outlined"
              onClick={handleClose}
              sx={styles.cancelButton}
            >
              Cancel
            </Button>

            <Button
              variant="contained"
              onClick={handleSave}
              disabled={
                loading || !imgSrc || (enableCropping && !completedCrop)
              }
              sx={styles.saveButton}
            >
              {loading ? <CircularProgress color="inherit" /> : "Save"}
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

const styles = {
  modalBox: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    maxWidth: 600,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
    maxHeight: "90vh",
    overflow: "auto",
  },
  modalTitle: {
    mb: 1,
    textAlign: "center",
  },
  errorText: {
    textAlign: "center",
    mb: 2,
  },
  aspectInfo: {
    textAlign: "center",
    color: "text.secondary",
    mb: 2,
  },
  uploadSection: {
    display: "flex",
    justifyContent: "center",
    mb: 2,
  },
  uploadButton: {
    backgroundColor: "#1976d2",
  },
  cropContainer: {
    display: "flex",
    justifyContent: "center",
    mb: 2,
  },
  imagePreview: {
    display: "flex",
    justifyContent: "center",
    mb: 2,
    maxHeight: "50vh",
    overflow: "hidden",
  },
  cropImage: {
    maxWidth: "100%",
    maxHeight: "50vh",
  },
  previewImage: {
    maxWidth: "100%",
    maxHeight: "50vh",
    objectFit: "contain",
  },
  actionButtons: {
    display: "flex",
    justifyContent: "flex-end",
    gap: 2,
    mt: 2,
  },
  cancelButton: {
    color: "#d32f2f",
    borderColor: "#d32f2f",
    "&:hover": {
      borderColor: "#b71c1c",
    },
  },
  saveButton: {
    backgroundColor: "#2e7d32",
    "&:hover": {
      backgroundColor: "#1b5e20",
    },
  },
  croppingToggle: {
    display: "flex",
    justifyContent: "center",
    mb: 2,
  },
};

export default ImageEditor;
